import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'
import { disableElement, enableElement } from '../../frontend/support/helpers'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  static values = {
    timeout: Number,
    href:    String
  }

  static classes = [
    'loading'
  ]

  initialize() {
    this.loaded       = this.loaded.bind(this)
    this.streamLoaded = this.streamLoaded.bind(this)
  }

  disconnect() {
    this.#removeListeners()
  }

  // ==== Controllers

  // ==== Actions

  loading() {
    this.frame?.addEventListener('turbo:frame-render', this.loaded, { passive: true, once: true })
    window.addEventListener('turbo:before-fetch-response', this.streamLoaded, { passive: true, once: true })

    $fq(this.buttonElement).addClass(this.loadingClass)

    this.loadingTimeout = setTimeout(() => {
      this.loaded()
    }, this.timeoutValue)

    // Small timeout to allow the link to serialize and perform its action
    // before removing the href/ disabling
    setTimeout(() => {
      disableElement(this.element)
    }, 13)
  }

  loaded() {
    this.#removeListeners()

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = null
    }

    enableElement(this.element)

    $fq(this.buttonElement).removeClass(this.loadingClass)
  }

  // ==== Getters

  /**
   * @field {HTMLElement}
  */
  get buttonElement() {
    if (this.hasButtonTarget) return this.buttonTarget

    return this.element
  }

  get frame() {
    if (this.element.dataset.turboFrame) {
      return document.getElementById(this.element.dataset.turboFrame)
    }
    if (this.element.dataset.remote === 'true') return null

    return this.element.closest('turbo-frame')
  }

  get form() {
    return this.buttonElement.form ?? this.buttonElement.closest('form')
  }

  get href() {
    if (this.buttonElement.nodeName === 'A') {
      return this.buttonElement.href || this.buttonElement.dataset.href
    }

    return this.form?.action
  }

  // ==== Setters

  // ==== Private

  streamLoaded({ detail: { fetchResponse } }) {
    if (fetchResponse.location.href !== this.href) return
    if (!fetchResponse.contentType.includes('text/vnd.turbo-stream')) return

    this.loaded()
  }

  #removeListeners() {
    this.frame?.removeEventListener('turbo:frame-render', this.loaded)
    window.removeEventListener('turbo:before-fetch-response', this.streamLoaded)
  }

  // ==== Channels

}
