import ApplicationController    from '../support/application_controller'
import { timeout, metaContent } from '../support/helpers'
import { COLORS }               from '../../components/consultation_lobby_card_component/timer_controller'
import { playSound }            from '../support/audio_sound'

export default class extends ApplicationController {

  static targets = [
    'answerButton',
    'answerButton',
    'counter',
    'tabPanel',
    'tabWrapper',
    'timer',
    'videomedCall'
  ]

  static classes = [
    ...COLORS
  ]

  static outlets = [
    'user-status',
    'queue-preferences',
    'online-doctors'
  ]

  videomedCallTargetConnected(videomedCall) {
    this.removeOffHoldCards(videomedCall)
    this.#updateCounter(videomedCall.dataset.listName)
  }

  videomedCallTargetDisconnected(videomedCall) {
    this.#updateCounter(videomedCall.dataset.listName)
    this.#updateTabColor(videomedCall.dataset.listName)
  }

  async answerButtonTargetConnected(answerButton) {
    // don't ring if answer button is gone
    if (!answerButton.isConnected) return

    const { listName, sound = '#notification' } = answerButton.dataset
    await timeout(0) // wait for outlets to connect
    if (!this.#shouldNotify(listName)) return

    // stfu, i'm devving!
    if (window.debugMode) {
      HF.consoleLog(`RING RING!!! (Playing '${sound}')`)
    } else {
      playSound(sound)
    }
  }

  removeOffHoldCards(callCard) {
    const calls = this.#getVideomedCalls(callCard.dataset.listName)

    if (callCard.dataset.doctorId !== '' && callCard.dataset.doctorId !== this.currentUserId) {
      callCard.remove()
    }

    if (callCard.id !== calls[0].id) {
      callCard.querySelector('[data-gp-lobby-target="answerButton"]')?.remove()
    }
  }

  // ==== Actions

  updateTabColor({ params: { listName } }) {
    this.#updateTabColor(listName)
  }

  // ==== Getters

  get currentUserId() {
    return metaContent('app:user:id')
  }

  get currentUserActive() {
    return this.userStatusOutlet.activeValue
  }

  get colorClasses() {
    return COLORS.map((color) => this.#getTabStyleClass(color))
  }

  get onlineDoctorsCount() {
    return this.onlineDoctorsOutlet.onlineDoctorsCount
  }

  // ==== Setters

  // ==== Private

  #updateCounter(listName) {
    this.#getCounterTarget(listName).textContent = this.#getVideomedCallCount(listName)
  }

  #getCounterTarget(listName) { return this.#getListTarget('counter', listName) }

  #getTabWrapperTarget(listName) { return this.#getListTarget('tabWrapper', listName) }

  #getTabPanelTarget(listName) { return this.#getListTarget('tabPanel', listName) }

  #getTimerTarget(listName) { return this.#getListTarget('timer', listName) }

  #getListTarget(target, listName) {
    return this[`${target}Targets`]
      .find((counter) => counter.dataset.listName === listName)
  }

  #getVideomedCallCount(listName) {
    return this.#getVideomedCalls(listName).length
  }

  #getVideomedCalls(listName) {
    return this.videomedCallTargets
      .filter((videomedCall) => videomedCall.dataset.listName === listName)
  }

  #setTabStyle(tabElement, color) {
    if (!tabElement) return

    tabElement.classList.remove(...this.colorClasses)
    tabElement.classList.add(this.#getTabStyleClass(color))
  }

  #getTabStyleClass(color) {
    return this[`${color}Class`]
  }

  #setTabColor(listName, color) {
    this.#setTabStyle(this.#getTabWrapperTarget(listName), color)
    this.#setTabStyle(this.#getTabPanelTarget(listName), color)
  }

  #getTopTimer(listName) {
    const target = this.#getTimerTarget(listName)
    if (!target) return null

    return this.getControllerForElement(target, 'consultation-lobby-card-component--timer')
  }

  #updateTabColor(listName) {
    this.#setTabColor(listName, this.#getTopTimer(listName)?.colorValue ?? 'success')
  }

  #shouldNotify(listName) {
    if (!this.currentUserActive) return false
    if (this.onlineDoctorsCount === 1) return true

    return this.queuePreferencesOutlet.shouldNotify(listName)
  }

}
