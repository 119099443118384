import Rails     from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import LocalTime from 'local-time'
import morphdom  from 'morphdom'
import zxcvbn    from 'zxcvbn'
import $         from 'jquery'
// import { Turbo, cable } from '@hotwired/turbo-rails'

import 'what-input'
import 'foundation-sites'

// ==== Stimulus

import '../controllers'

// ==== lib

import '../lib'

// ==== jQuery

window.jQuery = $
window.$ = $

// ==== zxcvbn

window.zxcvbn = zxcvbn

// ==== LocalTime

LocalTime.config.i18n.en.date.on = '{date}'
LocalTime.config.i18n.en.date.formats.thisYear = '%b %e at %H:%M'
LocalTime.config.i18n.en.time.formats.default = '%H:%M'
LocalTime.config.i18n.en.datetime.formats.default = '%B %e, %Y at %H:%M'
LocalTime.config.i18n.en.date.yesterday = 'Yesterday'
LocalTime.config.i18n.en.date.today = 'Today'
LocalTime.config.i18n.en.date.tomorrow = 'Tomorrow'
LocalTime.start()

// ==== Turbo

function setup() {
  $(document).foundation() // eslint-disable-line no-undef
  const turboBootIndicator = document.getElementById('turbo_hasnt_loaded_yet')
  if (turboBootIndicator) turboBootIndicator.remove()
  document.documentElement.setAttribute('data-turbo-loaded', '')
  return undefined
}

function pwa() {
  if (!navigator.serviceWorker.controller) {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
  }
}

function teardown() {
  return undefined
}

const Application = {
  setup,
  teardown,
  pwa
}

document.addEventListener('turbo:load', Application.setup, { once: true })
document.addEventListener('turbo:load', Application.pwa)
document.addEventListener('turbo:render', Application.setup)
document.addEventListener('turbo:before-render', Application.teardown)

Turbo.StreamActions.morph = function _morph() {
  this.targetElements.forEach((e) => morphdom(e, this.templateContent))
}

Turbo.StreamActions.refresh = function _refresh() {
  Turbo.session.visit(this.baseURI)
}

Turbo.StreamActions.visit = function _visit() {
  Turbo.session.visit(this.getAttribute('href'))
}

// ==== Rails & Turbo

if (import.meta.env.DEV) Rails.start()
Turbo.start()

// ==== ActiveStorage

// require('@rails/activestorage').start()
