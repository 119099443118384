import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static outlets = [
    'twilio-video'
  ]

  connect() {
    this.fetchJSON()
    this.createVideomedCallChannel()
  }

  // ==== Actions

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  get currentUserId() {
    return this.data.get('currentUserId')
  }

  get currentUserType() {
    return this.data.get('currentUserType')
  }

  get videomedCallId() {
    return this.data.get('videomedCallId')
  }

  get videomedCallStatus() {
    return this.data.get('videomedCallStatus')
  }

  get videomedCallGreetingComplete() {
    return this.data.get('videomedCallGreetingComplete')
  }

  // ==== Setters

  set videomedCallStatus(value) {
    this.data.set('videomedCallStatus', value)
  }

  set videomedCallGreetingComplete(value) {
    this.data.set('videomedCallGreetingComplete', value)
  }

  // ==== Private

  fetchJSON() {
    if (this.videomedCallStatus === 'ringing') {
      const self = this
      const url  = `/api/v1/videomed-calls/${this.videomedCallId}`

      fetch(url, { method: 'GET' })
        .then((resp) => resp.json())
        .then((json) => {
          const status = json.data.attributes.status

          self.updateVideomedCallStatus(status)
        })
        .catch((_error) => {})
    }
  }

  updateVideomedCallStatus(status) {
    this.videomedCallStatus = status

    if (this.hasTwilioVideoOutlet) {
      this.twilioVideoOutlet.videomedCallStatus = status
    }
  }

  updateVideomedCallGreetingComplete(value) {
    this.videomedCallGreetingComplete = value

    if (this.hasTwilioVideoOutlet) {
      this.twilioVideoOutlet.videomedCallGreetingComplete = value
    }
  }

  // ==== Channels

  createVideomedCallChannel = () => {
    const self = this

    CreateChannel({
      channel:          'VideomedCallChannel',
      consultation_id:  self.consultationId,
      videomed_call_id: self.videomedCallId
    }, {
      received(data) {
        self.updateVideomedCallStatus(data.status)
        self.updateVideomedCallGreetingComplete(data.greeting_complete)
      }
    })
  }

}
