import { get }          from '@rails/request.js'
import BmiCalculator    from '../../../../support/bmi_calculator'
import Vo2MaxCalculator from '../../../../support/vo2_max_calculator'
import WizardController from '../../../wizard_controller'
import WizardMixin      from '../../../../support/wizard_mixin'

export default class extends WizardMixin(WizardController) {

  static targets = [
    ...super.targets,
    'bloodPressureDiastolic',
    'bloodPressureSystolic',
    'bmi',
    'chesterReadingsInput',
    'chesterStepReadings',
    'consent',
    'consentInput',
    'contraindicationsNoneOfTheAbove',
    'contraindicationsWarnings',
    'diastolicAtRest',
    'height',
    'hivConfirmationTestResultInconclusive',
    'hivConfirmationTestResultReactive',
    'hivTestResult',
    'pulseAtRest',
    'pulseEscalation',
    'repeatBloodPressure',
    'systolicAtRest',
    'testCompletion',
    'testCompletionInverse',
    'testEscalation',
    'testEscalationWarnings',
    'testPerform',
    'urineTestStrip',
    'voMaxResult',
    'voMaxStatus',
    'voPulse',
    'weight'
  ]

  static values = {
    ...super.values,
    rangeCheckUrl: String,
    rangeDataUrl:  String,
    voMaxMatrix:   Object,
    voMaxSex:      String
  }

  static outlets = [
    'consultation-note'
  ]

  connect() {
    Vo2MaxCalculator(this)
    BmiCalculator(this)
    this.fetchEscalationRanges()
  }

  initialize() {
    this.diastolicAtRestMax   = null
    this.elevatedBpMessage    = null
    this.elevatedPulseMessage = null
    this.pulseAtRestMax       = null
    this.systolicAtRestMax    = null
  }

  // ==== Controllers

  // ==== Actions

  validReadingInputs() {
    return this.chesterReadingsInputTargets.every((input) => input.value !== '')
  }

  // === Escalation

  async fetchEscalationRanges() {
    const escalationRangeData = await this.getEscalationRanges()

    this.diastolicAtRestMax   = escalationRangeData.diastolic_at_rest_max
    this.elevatedBpMessage    = escalationRangeData.elevated_bp_message
    this.elevatedPulseMessage = escalationRangeData.elevated_pulse_message
    this.pulseAtRestMax       = escalationRangeData.pulse_at_rest_max
    this.systolicAtRestMax    = escalationRangeData.systolic_at_rest_max
  }

  async getEscalationRanges() {
    const response = await get(this.rangeDataUrlValue, { responseKind: 'json' })
    const body     = await response.json

    return body
  }

  async checkBloodPressureRange(event) {
    const queryData = new FormData()

    this.consultationNoteOutlet.autoSave(event)

    queryData.append(this.bloodPressureDiastolicTarget.name, this.bloodPressureDiastolicTarget.value)
    queryData.append(this.bloodPressureSystolicTarget.name, this.bloodPressureSystolicTarget.value)

    const response = await get(this.rangeCheckUrlValue, { query: queryData })
    const body     = await response.json

    this.toggleTarget(this.repeatBloodPressureTarget, body.out_of_range)
  }

  // === Toggles

  toggleHivTestTaken(event) {
    if (event.currentTarget.value === 'patient_tested_today') {
      this.showRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    }
  }

  toggleHivTestResult(event) {
    [
      { value: 'reactive', target: this.hivConfirmationTestResultReactiveTarget },
      { value: 'inconclusive', target: this.hivConfirmationTestResultInconclusiveTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleUrineTestStrip({ currentTarget }) {
    this.toggleTarget(this.urineTestStripTarget, currentTarget.checked)
  }

  toggleContraindicationsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.contraindicationsNoneOfTheAboveTargets,
      dispatchInput: false
    })
    this.#toggleChesterStepReadings(
      this.contraindicationsNoneOfTheAboveTargets
    )
  }

  toggleTestEscalation({ currentTarget }) {
    if (currentTarget.value === '') return
    if (this.systolicAtRestValue === 0 || this.diastolicAtRestValue === 0) return

    const elevatedBpAtRest       = this.#isElevatedBP()
    const elevatedPulseAtRest    = this.#isElevatedPulse()
    const elevatedReadingsAtRest = (elevatedBpAtRest || elevatedPulseAtRest)

    if (elevatedReadingsAtRest) this.warningMessage = this.#i18nMessage(elevatedBpAtRest, elevatedPulseAtRest)

    this.toggleTarget(this.pulseEscalationTarget, !elevatedBpAtRest)
    this.toggleTarget(this.consentTarget, !elevatedReadingsAtRest)
    this.toggleTarget(this.testPerformTarget, this.consentInputTarget.checked && !elevatedReadingsAtRest)
    this.toggleTarget(this.testEscalationWarningsTarget, elevatedReadingsAtRest)
  }

  toggleTestPerform({ currentTarget: { checked } }) {
    this.toggleTarget(this.testPerformTarget, checked)
  }

  toggleTestCompletion({ currentTarget: { checked } }) {
    this.toggleTarget(this.testCompletionInverseTarget, checked)
    this.toggleTarget(this.testCompletionTarget, !checked)
  }

  // ==== Getters

  get systolicAtRestValue() {
    const value = parseInt(this.systolicAtRestTarget.value, 10)
    return Number.isNaN(value) ? 0 : value
  }

  get diastolicAtRestValue() {
    const value = parseInt(this.diastolicAtRestTarget.value, 10)
    return Number.isNaN(value) ? 0 : value
  }

  get pulseAtRestValue() {
    const value = parseInt(this.pulseAtRestTarget.value, 10)
    return Number.isNaN(value) ? 0 : value
  }

  // ==== Setters

  /**
   * @param {any} message
   */
  set warningMessage(message) {
    this.testEscalationWarningsTarget.querySelector('p').innerHTML = message
  }

  // === Private

  #toggleChesterStepReadings(...targetGroups) {
    const allNoneChecked = targetGroups.every((targets) => {
      const noneCheckbox = targets.find((target) => target.value === 'none_of_the_above')
      if (!noneCheckbox) throw new Error(`missing none_of_the_above checkbox: ${targets[0]?.name || 'unknown'}`)
      return noneCheckbox.checked || targets.every((target) => !target.checked)
    })

    this.chesterStepReadingsTarget.classList.toggle(this.hideClass, !allNoneChecked)
    this.contraindicationsWarningsTarget.classList.toggle(this.hideClass, allNoneChecked)
  }

  #isElevatedBP() {
    return this.systolicAtRestValue > this.systolicAtRestMax || this.diastolicAtRestValue > this.diastolicAtRestMax
  }

  #isElevatedPulse() {
    return this.pulseAtRestValue > this.pulseAtRestMax
  }

  #i18nMessage(elevatedBpAtRest, elevatedPulse) {
    if (elevatedPulse && !elevatedBpAtRest) return this.elevatedPulseMessage

    return this.elevatedBpMessage
  }

}
